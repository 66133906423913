/* Works on Firefox */
* {
  scrollbar-width: 0;
  scrollbar-color: transparent;
  outline: none;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: transparent;
}

article {
  padding: 0.75em 0 !important;
}

p {
  display: block;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;

    --radius: 0.5rem;

    /* Custom properties */
    --navigation-height: 3.5rem;
    --color-one: #ffbd7a;
    --color-two: #fe8bbb;
    --color-three: #9e7aff;

    /* 
    --color-one: #37ecba;
    --color-two: #72afd3;
    --color-three: #ff2e63;
     */
  }

  .dark {
    --background: 0 0% 0%;
    --foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  #__next,
  #root,
  body {
    @apply bg-coal-500 text-foreground min-h-dvh select-none font-sans;
  }
}

/* add the code bellow */
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  /* 自定义滚动条样式 */
  .custom-scrollbar::-webkit-scrollbar {
    width: 4px;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: var(--gray-300);
    border-radius: 4px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: var(--gray-500);
    border-radius: 4px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: var(--gray-600);
  }

  /* 为Firefox设置滚动条样式 */
  .custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #888 var(--gray-300);
  }
}

@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';
@import 'yet-another-react-lightbox/plugins/counter.css';
@import 'yet-another-react-lightbox/styles.css';
@import '../../../packages/app/features/posts/PostDetail/css/post-detail-modal.css';

.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  background: rgba(255, 255, 255, 0.8);
}

.swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-button-prev,
.swiper-button-next {
  width: 36px !important;
  height: 36px !important;
  border-radius: 24px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  width: 36px !important;
  height: 36px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 24px;
  color: #fff !important;
  font-size: 18px;
}

.swiper-button-prev:after {
  text-indent: -2px;
}
.swiper-button-next:after {
  text-indent: 2px;
}
